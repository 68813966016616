<template>
  <popup-layout class="small_size" :class="{ open: isOpen }">
    <template #title>
      <h1 class="font_h6 color_text mb24" v-html="state.title" />
    </template>
    <template #btn>
      <div class="btn_center">
        <button ref="confirmButtonRef" type="button" class="btn_ty_01 solid size_1" @click="confirm">
          {{ state.confirmText }}
        </button>
      </div>
    </template>
  </popup-layout>
</template>

<script setup lang="ts">
import usePopup from '../composables/popup'
import { type IPopupAlert } from '../types/popup'
import PopupLayout from './PopupLayout.vue'

const { isOpen, setOpenCallback, open: popupOpen, close } = usePopup()

const state = reactive<IPopupAlert>(
  { title: '', confirmText: '', confirm: undefined }
)

const confirmButtonRef = ref<any>(null)

const confirm = () => {
  state.confirm && state.confirm()

  close()
}

const init = (title: string, confirm?: Function) => {
  state.title = title
  state.confirm = confirm

  setOpenCallback(() => {
    // Enter 시 팝업 닫기 Action을 위한 설정
    if (confirmButtonRef.value) {
      confirmButtonRef.value.focus()
    }
  })
}

const open = (config: IPopupAlert) => {
  state.title = config.title
  state.confirm = config.confirm
  state.confirmText = config.confirmText ?? '확인'

  setOpenCallback(() => {
    // Enter 시 팝업 닫기 Action을 위한 설정
    if (confirmButtonRef.value) {
      confirmButtonRef.value.focus()
    }
  })

  popupOpen()
}

defineExpose({
  open,
  close
})
</script>

<style scoped>
.title {
  padding: 24px 24px 16px;
  font-size: 20px;
}

.fixed_bottom {
  padding: 16px 24px;
}

.fixed_bottom::after {
  content: '';
  display: block;
  clear: both;
}
</style>
