import { type IPopupAlert, type IPopupConfirm } from '~/modules/popup/modal/types/popup';

export default function usePopup() {
  const popupAlertRef = ref<any>(null)
  const popupConfirmRef = ref<any>(null)

  const openByAlert = (alert: IPopupAlert) => {
    popupAlertRef.value && popupAlertRef.value.open(alert)
  }

  const closeByAlert = () => {
    popupAlertRef.value && popupAlertRef.value.close()
  }

  const openByConfirm = (confirm: IPopupConfirm) => {
    popupConfirmRef.value && popupConfirmRef.value.open(confirm)
  }

  const closeByConfirm = () => {
    popupConfirmRef.value && popupConfirmRef.value.close()
  }

  return {
    popupAlertRef,
    popupConfirmRef,
    openByAlert,
    closeByAlert,
    openByConfirm,
    closeByConfirm,
  };
}
