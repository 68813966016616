<template>
  <TransitionGroup tag="div" class="snackbar_box" name="list">
    <div v-for="item in list" :key="item.key" class="snackbar" :class="[item.type]">
      <p class="font_p2">
        {{ item.title }}
      </p>
      <div v-if="item.action" class="btn">
        <a href="#" class="link_line" @click.prevent="item.action">{{ item.actionTitle ?? '' }}</a>
      </div>
    </div>
  </TransitionGroup>
</template>

<script setup lang="ts">
import { StringGenerator } from '@tms/utils'
import { type ISnackbarItem, type TSnackBarType } from '../types/snackbar'

const list = ref<ISnackbarItem[]>([])

const success = (title: string, actionTitle?: string, action?: Function) => add(title, 'success', action, actionTitle)
const info = (title: string, actionTitle?: string, action?: Function) => add(title, 'info', action, actionTitle)

const add = (title: string, type: TSnackBarType, action?: Function, actionTitle?: string) => {
  const key = StringGenerator.generateUUID()

  list.value.push({ key, title, action, actionTitle, type })
  setTimeout(() => {
    remove(key)
  }, 3000)
}

const remove = (key: string) => {
  list.value = list.value.filter(item => item.key !== key)
}

defineExpose({
  success,
  info
})

</script>

<style scoped>
.snackbar_box { display: block; position: fixed; left: 50%; margin-left:-244px; bottom:40px; z-index:160; box-sizing: border-box; border-radius: 4px; }
.snackbar_box .snackbar { width: 488px; min-height: 50px; box-sizing: border-box; border-radius: 8px; position: relative; margin-top:12px; background:var(--color-background-sub2) }
.snackbar_box .snackbar p { padding:12px 108px 12px 16px; color:var(--color-background-default); }
.snackbar_box .snackbar .btn{ position:absolute; right:16px; top:16px; text-align:right; }
.snackbar_box .snackbar .btn a{ color:var(--color-background-default); }

.snackbar_box .snackbar.success{ background:var(--color-solid-point) }
.snackbar_box .snackbar.success p{ color:var(--color-text-title) }
.snackbar_box .snackbar.success .btn a{ color:var(--color-text-title) }

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(0px);
}

.list-leave-active {
  position: absolute;
}

@media screen and (max-width: 768px) {
  .snackbar_box { margin-left:-167.5px; }
  .snackbar_box .snackbar{ width:335px; }
}

</style>
