<template>
  <popup-layout class="small_size" :class="{ open: isOpen }">
    <template #contents>
      <p class="font_h6 color_text" v-html="state.title" />
      <p class="font_p3 mb24" v-html="state.description" />
    </template>
    <template #btn>
      <div class="btn_center">
        <button ref="focusRef" type="button" class="btn_ty_01 size_1" @click="cancel">
          {{ state.cancelText }}
        </button>
        <button type="button" class="btn_ty_01 solid size_1" @click="confirm">
          {{ state.confirmText }}
        </button>
      </div>
    </template>
  </popup-layout>
</template>

<script setup lang="ts">

import usePopup from '../composables/popup'
import { type IPopupConfirm } from '../types/popup'
import PopupLayout from './PopupLayout.vue'

const { isOpen, open: popupOpen, close } = usePopup()

const state = reactive<IPopupConfirm>({
  title: '',
  description: '',
  confirmText: '',
  confirm: undefined,
  cancelText: '',
  cancel: undefined
})

const focusRef = ref<HTMLElement | null>(null)

// open 액션이 있을때 포커스를 버튼으로 이동
watch(isOpen, (value) => {
  if (value) {
    focusRef.value?.focus()
  }
})

const cancel = () => {
  state.cancel && state.cancel()

  close()
}

const confirm = () => {
  state.confirm && state.confirm()

  close()
}

const open = (config: IPopupConfirm) => {
  state.title = config.title
  state.description = config.description ?? ''
  state.confirm = config.confirm
  state.confirmText = config.confirmText ?? '확인'
  state.cancel = config.cancel
  state.cancelText = config.cancelText ?? '취소'

  popupOpen()
}

defineExpose({
  open,
  close
})
</script>

<style scoped>
.title {
  padding: 24px 24px 16px;
  font-size: 20px;
}

.fixed_bottom {
  padding: 16px 24px;
}

.fixed_bottom::after {
  content: "";
  display: block;
  clear: both;
}

button:focus {
  box-shadow: 0 0 3px var(--color-input-focus-line);
}
</style>
