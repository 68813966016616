export default function useSnackbar () {
  const snackbarRef = ref<any>(null)

  const info = (message: string, actionTitle?: string, action?: Function) => {
    snackbarRef.value?.info(message, actionTitle, action)
  }

  const success = (message: string, actionTitle?: string, action?: Function) => {
    snackbarRef.value?.success(message, actionTitle, action)
  }

  return {
    snackbarRef,
    success,
    info
  }
}
